import React from 'react';
import { Controller } from 'react-hook-form';
import { Grid, Typography, TextField, FormControl, MenuItem, Select } from '@mui/material';

const CommonAssetForm = ({ control, setAssetType }) => {
  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="assetName"
          control={control}
          defaultValue=""
          rules={{ required: 'Asset Name is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Asset Name"
              variant="outlined"
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ''}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="assetType"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography variant="subtitle1">Asset Type:</Typography>
              <Select
                {...field}
                onChange={(e) => {
                  setAssetType(e.target.value);
                  field.onChange(e); 
                }}
              >
                <MenuItem value="Mesh">Mesh</MenuItem>
                <MenuItem value="PointCloud">PointCloud</MenuItem>
                <MenuItem value="Image">Image</MenuItem>
                <MenuItem value="Video">Video</MenuItem>
                <MenuItem value="Audio">Audio</MenuItem>
                <MenuItem value="PDF">PDF</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};

export default CommonAssetForm;
