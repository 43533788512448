import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SceneNameModal from './AddScene';
import { ApiContext } from '../context/ApiContext';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal, Button, Typography, Box } from '@mui/material';
import AssetForm from './AssetForm';
import { makeStyles } from '@mui/styles';
import { useUser } from '../context/UserContext';
import { styled } from '@mui/material/styles';
import logo from '../../src/logo.png'

const StyledTable = styled(MUIDataTable)(({ theme }) => ({
  '& .MuiTableHead-root': {
    background: '#d3d3d3',
  },
  '& .MuiTableCell-head': {
    background: '#d3d3d3',
  },
  '& .MuiToolbar-root:first-of-type': {
    backgroundColor: '#d3d3d3',
    minHeight: '50px',
    maxHeight: '50px',
  },
  '& .MuiToolbar-root:last-of-type': {
    backgroundColor: 'transparent',
  },
}));


const useStyles = makeStyles({
  root: {
    boxShadow: 'none !important',
    '& .MuiPaper-root': {
      boxShadow: 'none !important',
    },
  },
});

function Home({ user, token }) {
  const navigate = useNavigate();
  const { getAllScenes, deleteScenes } = useContext(ApiContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [selectedObjectID, setSelectedObjectID] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sceneName, setSceneName] = useState('');
  const { updateUserInfo } = useUser();


  useEffect(() => {
    if (user && token) {
      updateUserInfo(user, token);
    }
  }, [user, token, updateUserInfo]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      const objectIDs = selectedRows.map(id => data.find(item => item._id === id)?.objectID);
      if (!objectIDs.length) {
        alert('No items selected for deletion.');
        return;
      }

      // Perform the deletion
      await deleteScenes(objectIDs); // Make sure this is awaited
      alert('Selected items have been deleted successfully.');

      // Fetch the updated list of scenes
      const result = await getAllScenes("");
      setData(result.resource);
      setSelectedRows([]); // Clear selected rows
    } catch (error) {
      console.error('Failed to delete items:', error);
      alert('Failed to delete selected items.');
    }
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsNewModalOpen(false);
  };

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const handleOpenNewModal = () => {
    setIsNewModalOpen(true);
    handleClose();
  };

  const handleClick = (event, sceneId, sceneName) => {
    setAnchorEl(event.currentTarget);
    setSelectedObjectID(sceneId);
    setSceneName(sceneName);
  };

  // const onSubmit = (data) => {
  //   setModalOpen(false);
  // };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllScenes("");
        setData(result.resource);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, []);

  const handleSceneAdded = (newScene) => {
    setData(prevData => [...prevData, newScene]);
  };

  const columns = [
    {
      name: "sceneName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },

    },
    {
      name: "creatorName",
      label: "Created By",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "createTime",
      label: "Create Date",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "objectID",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const sceneId = tableMeta.rowData[3];
          const sceneName = tableMeta.rowData[0];
          return (
            <div style={{ padding: '5px' }}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={event => handleClick(event, sceneId, sceneName)}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          );
        }
      }
    }
  ];

  const handleViewAssets = () => {
    navigate('/assets', { state: { sceneId: selectedObjectID, sceneName: sceneName } });
  };

  return (
    <>
      <Box display="flex" justifyContent="center" marginBottom="20px">
        <img src={logo} alt="Logo" style={{ width: 384, height: 108 }} />
    </Box>
      <Typography variant='h4' style={{ fontWeight: 500, textAlign: 'center', margin: '20px 0' }}>Scene List</Typography>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" padding="10px">
        <SceneNameModal isOpen={isModalOpen} onClose={handleCloseModal} onSceneAdded={handleSceneAdded} />
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          {/* <Button
              variant="contained"
              color="primary"
              onClick={handleOpenNewModal}
              style={{backgroundColor: 'rgb(54, 54, 141)'}}
              disabled='true'
            >
              Duplicate
            </Button> */}
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            style={{ backgroundColor: "#AF2626" }}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Box style={{ maxHeight: '100%', width: '100%', overflowY: 'auto', padding: '10px' }}>
        <StyledTable
          data={data}
          columns={columns}
          style={{ boxShadow: 'none' }}
          options={{
            responsive: "standard",
            filter: true,
            search: true,
            download: false,
            print: false,
            viewColumns: false,
            selectableRows: "multiple",
            customToolbarSelect: () => { },
            onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
              setSelectedRows(allRowsSelected.map(row => data[row.dataIndex]._id));
            },
          }}
        />
                  <Menu
          anchorEl={anchorEl}
          id="long-menu"
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '200px',
              maxWidth: '200px',
              borderRadius: 20,
              fontFamily: "'Poppins', sans-serif",
            },
          }}
        >
          <MenuItem onClick={handleOpenNewModal}>
            Register Asset
          </MenuItem>
          <MenuItem onClick={handleViewAssets}> {/* Updated line */}
            View Assets
          </MenuItem>
          <MenuItem onClick={handleViewAssets}> {/* Updated line */}
            Duplicate Scene
          </MenuItem>
        </Menu>
        <Modal open={isNewModalOpen} onClose={handleCloseModal}>
          <div style={{ padding: '20px' }}>
            <AssetForm onClose={handleCloseModal} sceneId={selectedObjectID} />
          </div>
        </Modal>
      </Box>
    </>
  );
}

export default Home;