import React, { useContext, useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import '../styles/ThreeDAsset.css';
import { ApiContext } from '../context/ApiContext';
import CommonAssetForm from './CommonAssetForm';
import MeshAssetForm from './MeshAssetForm';
import Util from '../Util/Util';

function AssetForm({ onClose, sceneId }) {
  const { assetOperations, imageOperations, PDFOperations, audioOperations, videoOperations, getSceneById } = useContext(ApiContext);
  const { handleSubmit, control, watch } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [assetType, setAssetType] = useState('');
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const loadCompatibility = {
    Windows: 1,
    Agent: 1 << 5,
    Daemon: 1 << 7
  };

  const fileTypes = {
    Image: '.jpg,.jpeg,.png',
    Mesh: '.zip,.obj,.fbx,.glb,.gltf,.ply,.stl',
    PDF: '.pdf',
    Video: '.mp4',
    Audio: '.wav, .mp3, .ogg',
    PointCloud: '.las, .xyz, .ply'
  };

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [assetType]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const file = fileInputRef.current.files[0];
      if (!file) {
        console.error("No file selected");
        return;
      }
      const scene = await getSceneById(sceneId, '');

      const fileExtension = file.name.split('.').pop().toLowerCase();

      const meshObjectID = Util.generateOID('MeshAssetDataBucket', 'ry');
      const imageObjectID = Util.generateOID('ImageAsset', 'ry');
      const pdfObjectID = Util.generateOID('PDFAsset', 'ry');
      const videoObjectID = Util.generateOID('VideoAsset', 'ry');
      const audioObjectID = Util.generateOID('AudioAsset', 'ry');
      const pcObjectID = Util.generateOID('PointCloudAsset', 'ry');
      const commonBody = {
        ...data,
        createTime: Util.formatDate(),
        modifyTime: "",
        deleteTime: "",
        creatorName: 'ry',
        sceneID: sceneId,
        locationID: scene.resource[0]?.defaultLocationID,
        downloadable: false,
        isEnacted: false,
        displayAssetType: ''
      };

      switch (assetType) {
        case 'Mesh':
          const meshBody = {
            ...commonBody,
            objectID: meshObjectID,
            loadCompatibility: loadCompatibility.Windows | loadCompatibility.Agent | loadCompatibility.Daemon,
            realTransform: {
              position: {
                x: parseInt(data.threedTransformOffset.position.x, 10),
                y: parseInt(data.threedTransformOffset.position.y, 10),
                z: parseInt(data.threedTransformOffset.position.z, 10),
              },
              rotation: {
                x: parseInt(data.threedTransformOffset.rotation.x, 10),
                y: parseInt(data.threedTransformOffset.rotation.y, 10),
                z: parseInt(data.threedTransformOffset.rotation.z, 10),
                w: 1,
              },
              localScale: {
                x: 1,
                y: 1,
                z: 1,
              },
            },
            relativeAssetPath: `${meshObjectID}.${fileExtension}`,
            localAssetPath: `${meshObjectID}.${fileExtension}`,
            createdByMeshScanningAgent: false,
            makeVRInvisibleAfterLoading: false,
            displayAssetType: "",
          };
          if (meshBody.assetType === 'shelfObject') await assetOperations(meshBody, file);
          else await assetOperations(meshBody, file);
          console.log("Mesh asset operations completed successfully.");
          break;

        case 'Image':
          const imageBody = {
            ...commonBody,
            objectID: imageObjectID,
            imageCameraPose: {
              position: { x: 0.0, y: 0.0, z: 0.0 },
              rotation: { x: 0.0, y: 0.0, z: 0.0, w: 1.0 },
              localScale: { x: 1.0, y: 1.0, z: 1.0 },
            },
            loadCompatibility: 2147483647,
            requireManualLoad: false,
            relativeAssetPath: `${imageObjectID}.${fileExtension}`,
            localAssetPath: `${imageObjectID}.${fileExtension}`,
          };
          await imageOperations(imageBody, file);
          console.log("Image operations completed successfully.");
          break;

        case 'PDF':
          const pdfBody = {
            ...commonBody,
            objectID: pdfObjectID,
            loadCompatibility: 2147483647,
            requireManualLoad: false,
            relativeAssetPath: `${pdfObjectID}.${fileExtension}`,
            localAssetPath: `${pdfObjectID}.${fileExtension}`,
          };
          await PDFOperations(pdfBody, file);
          console.log("PDF operations completed successfully.");
          break;

        case 'Video':
          const videoBody = {
            ...commonBody,
            objectID: videoObjectID,
            loadCompatibility: 2147483647,
            requireManualLoad: false,
            relativeAssetPath: `${videoObjectID}.${fileExtension}`,
            localAssetPath: `${videoObjectID}.${fileExtension}`,
          };
          await videoOperations(videoBody, file);
          console.log("Video operations completed successfully.");
          break;

        // case 'PointCloud':
        //   const pcBody = {
        //     ...commonBody,
        //     objectID: pcObjectID,
        //     loadCompatibility: 2147483647,
        //     requireManualLoad: false,
        //     relativeAssetPath: `${pcObjectID}.${fileExtension}`,
        //     localAssetPath: `${pcObjectID}.${fileExtension}`,
        //   };
        //   // await pcOperations(pcBody, file);
        //   console.log("PointCloud operations completed successfully.");
        //   break;

        case 'Audio':
          const audioBody = {
            ...commonBody,
            objectID: audioObjectID,
            loadCompatibility: 2147483647,
            requireManualLoad: false,
            relativeAssetPath: `${audioObjectID}.${fileExtension}`,
            localAssetPath: `${audioObjectID}.${fileExtension}`,
          };
          await audioOperations(audioBody, file);
          console.log("Audio operations completed successfully.");
          break;

        default:
          console.error("Unsupported asset type:", assetType);
          break;
      }

      onClose();
    } catch (error) {
      console.error("Error during asset operations:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ style: { width: '80%', maxWidth: '600px' } }}
    >
      <DialogTitle>Asset Form</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <CommonAssetForm control={control} setAssetType={setAssetType} />
            {assetType === 'Mesh' && (
              <MeshAssetForm control={control} watch={watch} />
            )}
            <Grid item xs={12}>
              <Controller
                name="filePath"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="file"
                    inputProps={{ accept: fileTypes[assetType] }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Upload File"
                    variant="outlined"
                    inputRef={fileInputRef}
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                      field.onChange(e);
                    }}
                    helperText={field.value ? field.value.name : ''}
                  />
                )}
              />
              {assetType && (
                <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                  Acceptable file types: {fileTypes[assetType].split(',').join(', ')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Submit'}
                </Button>
                <Button onClick={onClose} color="error">Close</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AssetForm;