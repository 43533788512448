import React, { createContext } from 'react';
import Util from '../Util/Util';
import { saveAs } from 'file-saver';

export const ApiContext = createContext();

const ApiProvider = ({ children }) => {

    const apiUrl = "https://cf9zbak2y5.execute-api.us-east-1.amazonaws.com/v0";
    const apiLogin = async (username, password) => {
        const response = await fetch(`${apiUrl}/api/v2/mongodb/_table/FVRUserDataBucket?filter=(username=${username})and(password=${password})`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return data.resource[0];


    };

    const uploadFile = async (file, objectID) => {

        if (!file) {
            alert('Please select a file.');
            return;
        }

        try {
            const removeBeforePeriod = (str) => {
                const parts = str.split('.');
                return parts.length > 1 ? parts[1] : str;
            }
            const encodedFileName = encodeURIComponent(objectID + "." + removeBeforePeriod(file.name));
            const response = await fetch('https://cf9zbak2y5.execute-api.us-east-1.amazonaws.com/v0/upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fileName: encodedFileName,
                    fileType: file.type,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            const parsedResult = JSON.parse(result);

            const url = parsedResult.uploadURL;
            if (!url || typeof url !== 'string') {
                throw new Error('URL is undefined, null, or not a string');
            }

            const uploadResponse = await fetch(url, {
                method: 'PUT',
                body: file,
            });

            if (!uploadResponse.ok) {
                throw new Error(`HTTP error! Status: ${uploadResponse.status}`);
            }
        } catch (error) {
            console.error('There was an error uploading the file:', error);
        }
    }

    const getSceneByName = async (sceneName, modifyTime) => {
        const response = await fetch(`${apiUrl}/FVRSceneDataBucket?sceneName=${sceneName}&modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const getSceneById = async (objectId, modifyTime) => {
        const response = await fetch(`${apiUrl}/FVRSceneDataBucket?objectID=${objectId}&modifyTime=${modifyTime}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return data;
    };

    const getUserById = async (username) => {
        // Example API call
        const response = await fetch(`${apiUrl}/FVRUserDataBucket?username=${username}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return data;
    };

    const getShelfById = async (objectId, modifyTime) => {
        const response = await fetch(`${apiUrl}/SceneShelfDataBucket?sceneID=${objectId}&modifyTime=`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return data;
    };

    const getEnvironment = async (sceneID, modifyTime) => {
        const response = await fetch(`${apiUrl}/FVREnvironmentDataBucket?sceneID=${sceneID}&modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const getAllScenes = async (modifyTime) => {
        const response = await fetch(`${apiUrl}/FVRSceneDataBucket?modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const getAllUsers = async (modifyTime) => {
        const response = await fetch(`${apiUrl}/FVRUserDataBucket?modifyTime=${modifyTime}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const createScene = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "FVRSceneDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateScene = async (scene, body) => {
        const sceneID = scene.resource[0].objectID;
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "FVRSceneDataBucket",
                filters: `(objectID=${sceneID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateShelf = async (sceneID, body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                delete: false,
                collectionName: "SceneShelfDataBucket",
                filters: `(sceneID=${sceneID})and(modifyTime=)`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateMesh = async (mesh, body) => {
        const meshID = mesh.resource[0].objectID;
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "MeshAssetDataBucket",
                filters: `(objectID=${meshID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateImageAsset = async (imageID, body) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ImageAssetDataBucket",
                filters: `(objectID=${imageID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updatePdfAsset = async (pdfID, body) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "PdfAssetDataBucket",
                filters: `(objectID=${pdfID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateAudioAsset = async (audioID, body) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "AudioAssetDataBucket",
                filters: `(objectID=${audioID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateVideoAsset = async (videoID, body) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "VideoAssetDataBucket",
                filters: `(objectID=${videoID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createEnvironment = async (sceneName, locationID) => {
        const scene = await getSceneByName(sceneName, "");
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "FVREnvironmentDataBucket",
                resource: [
                    {
                        objectID: Util.generateOID('FVREnvironmentDataBucket', 'ry'),
                        createTime: Util.formatDate(),
                        modifyTime: "",
                        deleteTime: "",
                        creatorName: 'ry',
                        sceneID: scene.resource[0].objectID,
                        isEnacted: false,
                        sceneObjectIDs: [],
                        currDisplayMode: 0,
                        preloadedModelID: "",
                        scannedPointCloudID: "",
                        reprojectShadersToggle: 0,
                        realTimeArchetypeInfoList: [],
                        useSpatialAudio: false,
                        highAccGrabSync: true,
                        locationID: locationID
                    }
                ]

            }),
        });
        const data = await response.json();
        return data;
    };

    const updateEnvironment = async (envBody) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "FVREnvironmentDataBucket",
                filters: `(objectID=${envBody.objectID})and(modifyTime=))`,
                resource: [
                    envBody
                ]

            }),
        });
        const data = await response.json();
        return data;
    };

    const createShelf = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "SceneShelfDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };


    const createMesh = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "MeshAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createImageAsset = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ImageAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createPDFAsset = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: "PdfAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createAudioAsset = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "AudioAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createVideoAsset = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "VideoAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createThreeDAssetTask = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ThreeDAssetProcessTask",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createThreeDAsset = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ThreeDAssetDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createThreeDArchetype = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ThreeDArchetypeDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createImageArchetype = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "ImageArchetypeDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createPdfArchetype = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "PdfArchetypeDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const createAudioArchetype = async (body) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                collectionName: "AudioArchetypeDataBucket",
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const getMesh = async (sceneId, assetName) => {
        try {
            const modifyTime = '';
            const response = await fetch(`${apiUrl}/MeshAssetDataBucket?modifyTime=${modifyTime}&sceneID=${sceneId}&assetName=${assetName}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }


            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Failed to fetch mesh data:', error);
            throw error;
        }
    };

    const getThreeDAsset = async (sceneId, modifyTime) => {
        try {
            const response = await fetch(`${apiUrl}/ThreeDAssetDataBucket?modifyTime=${modifyTime}&sceneID=${sceneId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch mesh data:', error);
            throw error;
        }
    };

    const getMeshAssets = async (sceneId) => {
        try {
            const response = await fetch(`${apiUrl}/MeshAssetDataBucket?modifyTime=&sceneID=${sceneId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch mesh assets data:', error);
            throw error;
        }
    };

    const getImageAssets = async (sceneId) => {
        try {
            const response = await fetch(`${apiUrl}/ImageAssetDataBucket?modifyTime=&sceneID=${sceneId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch image assets data:', error);
            throw error;
        }
    };

    const getImageAsset = async (imageId) => {
        try {
            const response = await fetch(`${apiUrl}/ImageAssetDataBucket?objectID=${imageId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch image assets data:', error);
            throw error;
        }
    };

    const getMeshAsset = async (meshId) => {
        try {
            const response = await fetch(`${apiUrl}/MeshAssetDataBucket?objectID=${meshId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch image assets data:', error);
            throw error;
        }
    };

    const getPdfAsset = async (pdfId) => {
        try {
            const response = await fetch(`${apiUrl}/PdfAssetDataBucket?objectID=${pdfId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch image assets data:', error);
            throw error;
        }
    };

    const getVideoAsset = async (videoId) => {
        try {
            const response = await fetch(`${apiUrl}/VideoAssetDataBucket?objectID=${videoId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch image assets data:', error);
            throw error;
        }
    };

    const getAudioAsset = async (audioId) => {
        try {
            const response = await fetch(`${apiUrl}/AudioAssetDataBucket?objectID=${audioId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch image assets data:', error);
            throw error;
        }
    };

    const getVideoAssets = async (sceneId) => {
        try {
            const response = await fetch(`${apiUrl}/VideoAssetDataBucket?modifyTime=&sceneID=${sceneId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch video assets data:', error);
            throw error;
        }
    };

    const getAudioAssets = async (sceneId) => {
        try {
            const response = await fetch(`${apiUrl}/AudioAssetDataBucket?modifyTime=&sceneID=${sceneId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch audio assets data:', error);
            throw error;
        }
    };

    const getPDFAssets = async (sceneId) => {
        try {
            const response = await fetch(`${apiUrl}/PdfAssetDataBucket?modifyTime=&sceneID=${sceneId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch pdf assets data:', error);
            throw error;
        }
    };

    const deleteScenes = async (objectIDs) => {
        for (let objId of objectIDs) {
            const response = await fetch(`${apiUrl}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    collectionName: "FVRSceneDataBucket",
                    delete: true,
                    filters: `(objectID=${objId})and(modifyTime=))`,
                    resource: [
                        {}
                    ]
                }),
            });
        }
        return [];
    }

    const deleteAssets = async (objectIDs) => {
        for (let objId of objectIDs) {
            const response = await fetch(`${apiUrl}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    collectionName: "FVRSceneDataBucket",
                    delete: true,
                    filters: `(objectID=${objId})and(modifyTime=))`,
                    resource: [
                        {}
                    ]
                }),
            });
        }
        return [];
    }
    const imageRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.ImageAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "ImageAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify({
                                objectID: asset.objectID,
                                createTime: asset.createTime,
                                modifyTime: "",
                                deleteTime: "",
                                creatorName: asset.creatorName,
                                sceneID: asset.sceneID,
                                isEnacted: asset.isEnacted,
                                locationID: asset.locationID,
                                assetName: asset.assetName,
                                relativeAssetPath: asset.relativePath,
                                localAssetPath: asset.localAssetPath,
                                downloadable: asset.downloadable,
                                loadCompatibility: asset.loadCompatibility,
                                requireManualLoad: asset.requireManualLoad,
                                imageCameraPose: asset.imageCameraPose
                            })
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };

    const contentCreatedRMS = async (asset, scene) => {
        let contentType;
        switch (asset.assetType) {
            case 'Mesh':
                contentType = 'MeshAssetDataBucket';
                break;
            case 'Image':
                contentType = 'ImageAssetDataBucket';
                break;
            case 'Audio':
                contentType = 'AudioAssetDataBucket';
                break;
            case 'PDF':
                contentType = 'PdfAssetDataBucket';
                break;
            case 'Video':
                contentType = 'VideoAssetDataBucket';
                break;
            default:
                console.log("Invalid input");
                return;
        }
    
        // Stringify the asset object to embed inside the content
        const stringAsset = JSON.stringify(asset);
    
        // The content field now follows the format of the provided object
        const body = {
            topic: "SceneContentRefresh",
            subTopic: "ContentCreated",
            content: JSON.stringify({
                businessAccountID: 'Administration',
                sceneID: asset?.sceneID,
                content: [
                    {
                        content: stringAsset,
                        contentType: contentType
                    }
                ]
            })
        };
    
        console.log(body);
        Util.wsMessage(body);
    };
    

    const shelfUpdateRMS = async (shelf, scene) => {
        // Stringify the shelf object to embed inside the content
        const stringShelf = JSON.stringify(shelf);
    
        // The content field follows the provided format
        const body = {
            topic: "SceneContentRefresh",
            content: JSON.stringify({
                businessAccountID: 'Administration',
                sceneID: shelf?.sceneID,
                content: [
                    {
                        content: stringShelf,
                        contentType: 'SceneShelfDataBucket'
                    }
                ]
            }),
            subTopic: "ContentPatched"
        };
    
        Util.wsMessage(body);
    };        


    const imageArchetypeRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.ImageAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "ImageAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(
                                {
                                    objectID: Util.generateOID('ImageArchetypeDataBucket', asset.creatorName),
                                    createTime: Util.formatDate(),
                                    modifyTime: "",
                                    deleteTime: "",
                                    creatorName: asset.creatorName,
                                    sceneID: asset.sceneID,
                                    isEnacted: asset.isEnacted,
                                    locationID: asset.locationID,
                                    isNetworkVisible: asset.isNetworkVisible,
                                    scenePose: asset.imageCameraPose,
                                    followTargetID: "",
                                    followTargetPoseOffset: {
                                        position: asset?.imageCameraPose?.position,
                                        rotation: asset?.imageCameraPose?.rotation,
                                    },
                                    isGrabbable: true,
                                    isTappable: true,
                                    isTouchable: true,
                                    canBeHandled: true,
                                    isTargetable: true,
                                    isScaleable: true,
                                    isMappable: true,
                                    isAutoGrabbable: false,
                                    isThrowable: true,
                                    interactionState: 0,
                                    displayName: asset.assetName,
                                    iArchetypableID: asset.iArchetypableID,
                                    nameDisplayEnabled: true
                                }
                            )
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };

    const pdfArchetypeRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.PdfAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "PdfAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(
                                {
                                    objectID: Util.generateOID('PdfArchetypeDataBucket', asset.creatorName),
                                    createTime: Util.formatDate(),
                                    modifyTime: "",
                                    deleteTime: "",
                                    creatorName: asset.creatorName,
                                    sceneID: asset.sceneID,
                                    isEnacted: asset.isEnacted,
                                    locationID: asset.locationID,
                                    isNetworkVisible: asset.isNetworkVisible,
                                    scenePose: asset.imageCameraPose,
                                    followTargetID: "",
                                    followTargetPoseOffset: {
                                        position: asset?.imageCameraPose?.position,
                                        rotation: asset?.imageCameraPose?.rotation,
                                    },
                                    isGrabbable: true,
                                    isTappable: true,
                                    isTouchable: true,
                                    canBeHandled: true,
                                    isTargetable: true,
                                    isScaleable: true,
                                    isMappable: true,
                                    isAutoGrabbable: false,
                                    isThrowable: true,
                                    interactionState: 0,
                                    displayName: asset.assetName,
                                    iArchetypableID: asset.iArchetypableID,
                                    nameDisplayEnabled: true
                                }
                            )
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };

    const audioArchetypeRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.AudioAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "AudioAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(
                                {
                                    objectID: Util.generateOID('AudioArchetypeDataBucket', asset.creatorName),
                                    createTime: Util.formatDate(),
                                    modifyTime: "",
                                    deleteTime: "",
                                    creatorName: asset.creatorName,
                                    sceneID: asset.sceneID,
                                    isEnacted: asset.isEnacted,
                                    locationID: asset.locationID,
                                    isNetworkVisible: asset.isNetworkVisible,
                                    scenePose: {
                                        position: {
                                            x: 0,
                                            y: 0,
                                            z: 0
                                        },
                                        rotation: {
                                            x: 0,
                                            y: 0,
                                            z: 0,
                                            w: 1
                                        },
                                        localScale: {
                                            x: 1,
                                            y: 1,
                                            z: 1
                                        }
                                    },
                                    followTargetID: "",
                                    followTargetPoseOffset: {
                                        position: {
                                            x: 0,
                                            y: 0,
                                            z: 0
                                        },
                                        rotation: {
                                            x: 0,
                                            y: 0,
                                            z: 0,
                                            w: 1
                                        }
                                    },
                                    isGrabbable: true,
                                    isTappable: true,
                                    isTouchable: true,
                                    canBeHandled: true,
                                    isTargetable: true,
                                    isScaleable: true,
                                    isMappable: true,
                                    isAutoGrabbable: false,
                                    isThrowable: true,
                                    interactionState: 0,
                                    displayName: asset.assetName,
                                    iArchetypableID: asset.iArchetypableID,
                                    nameDisplayEnabled: true
                                }
                            )
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };


    const threeDArchetypeRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration", //update this when users integrated
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: {
                            rid: 1000
                        },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.ThreeDArchetypeDataBucket"
                    }
                ]
            },
            references: {
                version: 2,
                RefIds: [
                    {
                        rid: 1000,
                        type: {
                            class: "ThreeDArchetypeDataBucket",
                            ns: "FactualVR.DatabaseableObjects",
                            asm: "FactualVR"
                        },
                        data: {
                            objectID: asset.objectID,
                            createTime: asset.createTime,
                            modifyTime: asset.modifyTime,
                            deleteTime: asset.deleteTime,
                            creatorName: asset.creatorName,
                            sceneID: asset.sceneID,
                            isEnacted: false,
                            locationID: asset.locationnID,
                            isNetworkVisible: asset.isNetworkVisible,
                            scenePose: asset.realTransform,
                            followTargetID: "",
                            followTargetPoseOffset: {
                                position: asset?.realTransform?.position,
                                rotation: asset?.realTransform?.rotation
                            },
                            isGrabbable: true,
                            isTappable: true,
                            isTouchable: true,
                            canBeHandled: true,
                            isTargetable: true,
                            isScaleable: true,
                            isMappable: true,
                            isAutoGrabbable: false,
                            isThrowable: false,
                            interactionState: 0,
                            displayName: asset.displayName,
                            iArchetypableID: asset.iArchetypableID,
                            nameDisplayEnabled: true,
                            CurrRenderType: 1,
                            layerName: "UsableObject"
                        }
                    }
                ]
            },
            subTopic: "ContentCreated"
        }


        Util.wsMessage(body);
    };

    const shelfRMS = async (shelf, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: shelf.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.Features.Shelf.SceneShelfDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "SceneShelfDataBucket",
                                ns: "FactualVR.Features.Shelf",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(shelf)
                        }
                    ]
                }
            },
            subTopic: "ContentPatched"
        };

        Util.wsMessage(body);
    };

    const audioRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.AudioAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "AudioAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(asset)
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };

    const pdfRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.PdfAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "PdfAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(asset)
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };

    const videoRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.VideoAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "VideoAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(asset)
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };

    const meshRMS = async (asset, user = {}) => {
        let body = {
            topic: "SceneContentRefresh",
            content: {
                businessAccountID: "Administration",
                sceneID: asset.sceneID,
                content: [
                    {
                        dataBucket: { rid: 1000 },
                        dataBucketTypeString: "FactualVR.DatabaseableObjects.MeshAssetDataBucket"
                    }
                ],
                references: {
                    version: 2,
                    RefIds: [
                        {
                            rid: 1000,
                            type: {
                                class: "MeshAssetDataBucket",
                                ns: "FactualVR.DatabaseableObjects",
                                asm: "FactualVR"
                            },
                            data: JSON.stringify(asset)
                        }
                    ]
                }
            },
            subTopic: "ContentCreated"
        };

        Util.wsMessage(body);
    };



    const deleteScene = async (objectID) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: "FVRSceneDataBucket",
                delete: true,
                filters: `(objectID=${objectID})and(modifyTime=))`,
                resource: [
                    {}
                ]
            }),
        });
        const data = await response.json();
        return data;
    }

    const createLocation = async (locationBody) => {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: "VirtualLocationDataBucket",
                resource: [
                    locationBody
                ]
            }),
        });
        const data = await response.json();
        return data;
    }

    const updateLocation = async (locationID, body) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: "VirtualLocationDataBucket",
                filters: `(objectID=${locationID})and(modifyTime=${modifyTime})`,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateUser = async (userId, body) => {
        // Example API call
        console.log(userId)
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: "FVRUserDataBucket",
                filters: `(objectID=${userId})`,
                delete: false,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const updateAvatar = async (username, body) => {
        // Example API call
        console.log(username)
        console.log(body)
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: "HMDAvatarToolLicenseDataBucket",
                filters: `(ownerUserName=${username})`,
                delete: false,
                resource: [
                    body
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const deleteLicense = async (username, licenseName) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: `${licenseName}`,
                filters: `(ownerUserName=${username})`,
                delete: true,
                resource: [
                    {}
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const addLicense = async (username, licenseName) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                collectionName: `${licenseName}`,
                resource: [
                    {
                        objectID: Util.generateOID(licenseName, username),
                        createTime: Util.formatDate(),
                        modifyTime: '',
                        deleteTime: '',
                        creatorName: 'hypadmin',
                        ownerUserName: username
                    }
                ]
            }),
        });
        const data = await response.json();
        return data;
    };

    const getLocation = async (locationID) => {
        const modifyTime = ""
        const response = await fetch(`${apiUrl}?objectID=${locationID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    };

    const getAvatarLicense = async (username) => {
        const response = await fetch(`${apiUrl}/HMDAvatarToolLicenseDataBucket?ownerUserName=${username}`, {
            method: 'GET', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            }
        });
        const data = await response.json();
        return data;
    };

    const getAllAssetsByScene = async (sceneID) => {

        const meshAssets = await getMeshAssets(sceneID);
        const imageAssets = await getImageAssets(sceneID);
        const videoAssets = await getVideoAssets(sceneID);
        const audioAssets = await getAudioAssets(sceneID);
        const pdfAssets = await getPDFAssets(sceneID);

        const allAssets = [
            ...meshAssets.resource,
            ...imageAssets.resource,
            ...videoAssets.resource,
            ...audioAssets.resource,
            ...pdfAssets.resource,
        ];

        return allAssets;

    }

    const sceneOperations = async (sceneName) => {
        await createScene({
            objectID: Util.generateOID('FVRSceneDataBucket', 'ry'),
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: 'ry',
            sceneName: sceneName,
            environmentID: "",
            businessAccountID: 'Administration',
            networkLoadLocked: false,
            contentTypes: [],
            checkedOutUser: "",
            defaultLocationID: ""
        });

        const locationID = Util.generateOID("VirtualLocation", 'ry')

        const scene = await getSceneByName(sceneName, "");
        await createEnvironment(sceneName, locationID);
        const env = await getEnvironment(scene.resource[0].objectID, "");
        await createLocation({
            objectID: locationID,
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: "ry",
            sceneID: scene.resource[0].objectID,
            isEnacted: false,
            locationID: "",
            environmentID: env.resource[0].objectID,
            displayName: "DefautLocation"
        })
        await updateScene(scene,
            {
                objectID: scene.resource[0].objectID,
                createTime: Util.formatDate(),
                modifyTime: "",
                deleteTime: "",
                creatorName: 'ry',
                sceneName: sceneName,
                environmentID: env.resource[0].objectID,
                businessAccountID: 'Administration',
                networkLoadLocked: false,
                contentTypes: ["FVREnvironment"],
                checkedOutUser: "",
                defaultLocationID: locationID
            });
        await createShelf({
            objectID: Util.generateOID('SceneShelfDataBucket', 'ry'),
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: 'ry',
            sceneID: scene.resource[0].objectID,
            isEnacted: false,
            shelfItems: [],
            locationID: locationID
        });
        await updateScene(scene,
            {
                objectID: scene.resource[0].objectID,
                createTime: Util.formatDate(),
                modifyTime: "",
                deleteTime: "",
                creatorName: 'ry',
                sceneName: sceneName,
                environmentID: env.resource[0].objectID,
                businessAccountID: 'Administration',
                networkLoadLocked: false,
                contentTypes: ["FVREnvironment", "SceneShelf"],
                checkedOutUser: "",
                defaultLocationID: locationID
            });
    };

    const meshEnvironmentOperations = async (meshBody, file) => {
        await getMesh(meshBody.sceneID, meshBody.assetName); //checck for existing mesh
        const scene = await getSceneById(meshBody.sceneID, '');
        //create mesh
        await createMesh(meshBody);
        //file upload
        uploadFile(file, meshBody.objectID)
        let mesh = await getMesh(meshBody.sceneID, meshBody.assetName);
        meshBody.downloadable = true;
        //patch mesh --- only need to set download to true
        updateMesh(mesh, meshBody)
        //post threedassetbucket
        createThreeDAsset({
            "objectID": Util.generateOID('ThreeDAssetDataBucket', 'TBD'),
            "createTime": Util.formatDate(),
            "modifyTime": "",
            "deleteTime": "",
            "creatorName": "TBD",
            "sceneID": scene.resource[0].objectID,
            "isEnacted": false,
            "sceneObjectIDs": [
                mesh.resource[0].objectID
            ],
            "assetName": mesh.resource[0].assetName,
            "srcAssetPath": "TBD",
            "realTransform": {
                "position": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "rotation": {
                    "x": 0,
                    "y": 0,
                    "z": 0,
                    "w": 1
                },
                "localScale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                }
            },
            "DefaultRenderType": 1,
            "highResMeshID": mesh.resource[0].objectID,
            "baseRawPclID": "",
            "lowResMeshID": "",
            "highResPclID": "",
            "lowResPclID": "",
            "collisionMeshAssetID": "",
            "isScanned": false,
            "exportScanMeshID": ""
        })
        //post threedassetprocesstask
        let threedasset = await getThreeDAsset(scene.resource[0].objectID, '')
        await createThreeDAssetTask({
            objectID: Util.generateOID('ThreeDAssetProcessTask, TBD'),
            createTime: Util.formatDate(),
            modifyTime: "",
            deleteTime: "",
            creatorName: "TBD",
            status: 0,
            sceneID: scene.resource[0].objectID,
            threeDAssetId: threedasset.resource[0].objectID,
            isDoubleSidedMeshRequired: false,
            genPclFromMeshEnabled: false,
            userConfig: {
                isDoubleSidedMeshRequired: true,
                genPclFromMeshEnabled: true,
                limitLowResFaceCount: true,
                lowResTextureSize_MB: 500,
                createSimpleColliders: true,
                bypassErrorIfFailedProcessing: true
            }
        })
        //get virtual location
        const virLoc = await getLocation(scene.resource[0].defaultLocationID);
        const env = await getEnvironment(scene.resource[0].defaultLocationID, '');
        //post threedarchcetypedatabucket
        await createThreeDArchetype(
            {
                "objectID": Util.generateOID('ThreeDArchetype', 'ryan'),
                "createTime": Util.formatDate(),
                "modifyTime": "",
                "deleteTime": "",
                "creatorName": "ryan",
                "sceneID": scene.resource[0].objectID,
                "isEnacted": false,
                "locationID": scene.resource[0].defaultLocationID,
                "isNetworkVisible": true,
                "scenePose": {
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0,
                        "w": 1
                    },
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "followTargetID": "",
                "followTargetPoseOffset": {
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0,
                        "w": 1
                    }
                },
                "isGrabbable": false,
                "isTappable": false,
                "isTouchable": false,
                "canBeHandled": false,
                "isTargetable": false,
                "isScaleable": true,
                "isMappable": true,
                "isAutoGrabbable": false,
                "interactionState": 0,
                "displayName": meshBody.assetName,
                "iArchetypableID": threedasset.resource[0].objectID,
                "nameDisplayEnabled": false,
                "CurrRenderType": 1,
                "layerName": "UsableObject"
            }
        )
        //patch fvrenvironmentdatabucket
        env.resource[0].sceneObjectIDs.push(threedasset.resource[0].objectID)
        await updateEnvironment(env.resource[0])
    }
    const assetOperations = async (meshBody, file) => {
        try {
            await getMesh(meshBody.sceneID, meshBody.assetName); //checck for existing mesh
            let scene = await getSceneById(meshBody.sceneID, "");
            meshBody.locationID = scene.resource[0].defaultLocationID;
            await createMesh(meshBody);
            //remove
            await updateScene(scene, {
                "objectID": scene.resource[0].objectID,
                "createTime": Util.formatDate(),
                "modifyTime": "",
                "deleteTime": "",
                "creatorName": "hypadmin",
                "sceneName": scene.resource[0].sceneName,
                "environmentID": scene.resource[0].environmentID,
                "businessAccountID": scene.resource[0].businessAccountID,
                "networkLoadLocked": false,
                "contentTypes": [
                    "FVREnvironment",
                    "SceneShelf",
                    "MeshAsset"
                ],
                "checkedOutUser": "",
                "defaultLocationID": scene.resource[0].defaultLocationID,
            });
            let mesh = await getMesh(meshBody.sceneID, meshBody.assetName);
            await uploadFile(file, meshBody.objectID);
            await updateMesh(mesh, {
                "objectID": meshBody.objectID,
                "createTime": Util.formatDate(),
                "modifyTime": "",
                "deleteTime": "",
                "creatorName": "hypadmin",
                "sceneID": meshBody.sceneID,
                "isEnacted": false,
                "assetName": meshBody.assetName,
                "relativeAssetPath": meshBody.relativeAssetPath,
                "localAssetPath": meshBody.localAssetPath,
                "displayAssetType": "",
                "downloadable": true,
                "loadCompatibility": 161,
                "realTransform": {
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0,
                        "w": 1
                    },
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "createdByMeshScanningAgent": false,
                "makeVRInvisibleAfterLoading": false,
                "locationID": scene.resource[0].defaultLocationID,
            })

            createThreeDAsset({
                "objectID": Util.generateOID('ThreeDAssetDataBucket', 'TBD'),
                "createTime": Util.formatDate(),
                "modifyTime": "",
                "deleteTime": "",
                "creatorName": "TBD",
                "sceneID": scene.resource[0].objectID,
                "isEnacted": false,
                "sceneObjectIDs": [
                    mesh.resource[0].objectID
                ],
                "assetName": mesh.resource[0].assetName,
                "srcAssetPath": "TBD",
                "realTransform": {
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0,
                        "w": 1
                    },
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                },
                "DefaultRenderType": 1,
                "highResMeshID": mesh.resource[0].objectID,
                "baseRawPclID": "",
                "lowResMeshID": "",
                "highResPclID": "",
                "lowResPclID": "",
                "collisionMeshAssetID": "",
                "isScanned": false,
                "exportScanMeshID": ""
            })
            scene = await getSceneById(meshBody.sceneID, "");
            scene.resource[0].contentTypes.push("ThreeDAsset")
            await updateScene(scene,
                scene.resource[0]
            );
            //add some additional verification that threedasset  was made
            // await createScene(scene.resource[0]);
            let threedasset = await getThreeDAsset(scene.resource[0].objectID, '')
            await createThreeDAssetTask({
                objectID: Util.generateOID('ThreeDAssetProcessTask, TBD'),
                createTime: Util.formatDate(),
                modifyTime: "",
                deleteTime: "",
                creatorName: "TBD",
                status: 0,
                sceneID: scene.resource[0].objectID,
                threeDAssetId: threedasset.resource[0].objectID,
                isDoubleSidedMeshRequired: false,
                genPclFromMeshEnabled: false,
                userConfig: {
                    isDoubleSidedMeshRequired: true,
                    genPclFromMeshEnabled: true,
                    limitLowResFaceCount: true,
                    lowResTextureSize_MB: 500,
                    createSimpleColliders: true,
                    bypassErrorIfFailedProcessing: true
                }
            })

            //get scene shelf
            const shelf = await getShelfById(meshBody.sceneID, "");
            shelf.resource[0].shelfItems.push({
                "ItemType": 0,
                "DisplayName": meshBody.assetName,
                "ItemSearchID": threedasset.resource[0].objectID,
                "ItemDataBucketType": "ThreeDAssetDataBucket"
            })
            //patch scene shelf with modify time
            await updateShelf(meshBody.sceneID,

                shelf.resource[0]


            )
            await contentCreatedRMS(meshBody, scene.resource[0]);
            await shelfUpdateRMS(shelf.resource[0]);
        } catch (e) {
            console.log(e)
        }
    }

    const imageOperations = async (imageBody, file) => {
        try {
            await createImageAsset(imageBody);
            await uploadFile(file, imageBody.objectID)
            imageBody.downloadable = true;
            await updateImageAsset(imageBody.objectID, imageBody)
            //add image data to the shelf as a shelf object
            let shelfRes = await getShelfById(imageBody.sceneID, "");
            let shelf = shelfRes.resource[0];
            shelf.shelfItems.push({
                ItemType: 0,
                DisplayName: imageBody.assetName,
                ItemSearchID: imageBody.objectID,
                ItemDataBucketType: "ImageAssetDataBucket"

            })
            delete shelf._id;
            await updateShelf(imageBody.sceneID, shelf)
            const scene = await getSceneById(imageBody.sceneID)
            await contentCreatedRMS(imageBody, scene.resource[0]);
            await shelfUpdateRMS(shelf);
        } catch (e) {
            console.log(e)
        }
    }

    const PDFOperations = async (pdfBody, file) => {
        try {
            await createPDFAsset(pdfBody);
            await uploadFile(file, pdfBody.objectID)
            pdfBody.downloadable = true;
            await updatePdfAsset(pdfBody.objectID, pdfBody)
            //add image data to the shelf as a shelf object
            let shelfRes = await getShelfById(pdfBody.sceneID, "");
            let shelf = shelfRes.resource[0];
            shelf.shelfItems.push({
                ItemType: 0,
                DisplayName: pdfBody.assetName,
                ItemSearchID: pdfBody.objectID,
                ItemDataBucketType: "PdfAssetDataBucket"

            })
            delete shelf._id;
            await updateShelf(pdfBody.sceneID, shelf);
            const scene = await getSceneById(pdfBody.sceneID)
            await contentCreatedRMS(pdfBody, scene.resource[0]);
            await shelfUpdateRMS(shelf);
        } catch (e) {
            console.log(e)
        }
    }

    const audioOperations = async (audioBody, file) => {
        try {
            await createAudioAsset(audioBody);
            await uploadFile(file, audioBody.objectID);
            audioBody.downloadable = true;
            await updateAudioAsset(audioBody.objectID, audioBody);
            //add image data to the shelf as a shelf object
            let shelfRes = await getShelfById(audioBody.sceneID, "");
            let shelf = shelfRes.resource[0];
            shelf.shelfItems.push({
                ItemType: 0,
                DisplayName: audioBody.assetName,
                ItemSearchID: audioBody.objectID,
                ItemDataBucketType: "AudioAssetDataBucket"

            });
            delete shelf._id;
            await updateShelf(audioBody.sceneID, shelf);
            const scene = await getSceneById(audioBody.sceneID)
            await contentCreatedRMS(audioBody, scene.resource[0]);
            await shelfUpdateRMS(shelf);
        } catch (e) {
            console.log(e)
        }
    }

    const videoOperations = async (videoBody, file) => {
        try {
            await createVideoAsset(videoBody);
            await uploadFile(file, videoBody.objectID)
            videoBody.downloadable = true;
            await updateVideoAsset(videoBody.objectID, videoBody)
            //add image data to the shelf as a shelf object
            let shelfRes = await getShelfById(videoBody.sceneID, "");
            let shelf = shelfRes.resource[0];
            shelf.shelfItems.push({
                ItemType: 0,
                DisplayName: videoBody.assetName,
                ItemSearchID: videoBody.objectID,
                ItemDataBucketType: "VideoAssetDataBucket"

            })
            delete shelf._id;
            await updateShelf(videoBody.sceneID, shelf)
            const scene = await getSceneById(videoBody.sceneID)
            await contentCreatedRMS(videoBody, scene.resource[0]);
            await shelfUpdateRMS(shelf);
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <ApiContext.Provider value={{ imageArchetypeRMS, createImageArchetype, getPdfAsset, pdfArchetypeRMS, createPdfArchetype, createAudioArchetype, audioArchetypeRMS, getThreeDAsset, createThreeDArchetype, threeDArchetypeRMS, getUserById, getAvatarLicense, getImageAsset, getVideoAsset, getPdfAsset, getMeshAsset, getAudioAsset, updateAvatar, updateUser, uploadFile, addLicense, deleteLicense, apiLogin, deleteScene, deleteScenes, createScene, getAllUsers, createEnvironment, createShelf, sceneOperations, getAllScenes, assetOperations, getSceneByName, getSceneById, imageOperations, PDFOperations, audioOperations, videoOperations, getAllAssetsByScene }}>
            {children}
        </ApiContext.Provider>
    );
};

export default ApiProvider;