import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(() => {
    const savedUserInfo = localStorage.getItem('userInfo');
    return savedUserInfo ? JSON.parse(savedUserInfo) : null;
  });
  const [roles, setRoles] = useState(() => {
    const savedRoles = localStorage.getItem('roles');
    return savedRoles ? JSON.parse(savedRoles) : [];
  });

  const updateUserInfo = (user, token) => {
    setUserInfo(user);
    const fetchedRoles = fetchGroupsFromToken(token);
    setRoles(fetchedRoles);
    localStorage.setItem('userInfo', JSON.stringify(user));
    localStorage.setItem('roles', JSON.stringify(fetchedRoles));
  };

  const fetchGroupsFromToken = (token) => {
    if (!token || !token.split('.')[1]) {
      return [];
    }
    const encodedPayload = token.split('.')[1];
    const decodedPayload = atob(encodedPayload);
    const payload = JSON.parse(decodedPayload);
    return payload['cognito:groups'] || [];
  };

  const value = useMemo(() => ({ userInfo, roles, updateUserInfo }), [userInfo, roles]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};