import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import '../App.css';
import { Box } from '@mui/material';
import { useUser } from '../context/UserContext';
import logo from '../smallLogo.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

function Navbar({ signOut }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { roles, userInfo, updateUserInfo } = useUser();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleSignOut = () => {
    localStorage.clear();
    updateUserInfo(null, null); // Clear user context
    signOut();
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#d3d3d3' }}>
      <Toolbar style={{ minHeight: '64px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography variant="h6">
            <img src={logo} className="Nav-logo" alt="logo" />
          </Typography>
          <Button
            backgroundColor="white"
            aria-label="scenes"
            style={{ marginLeft: '40px', color: '#36368D', textTransform: 'none' }}
            onClick={() => navigate('/')}
          >
            Scenes
          </Button>
          {roles.includes('webadmin') && (
            <Button
              backgroundColor="white"
              aria-label="users"
              style={{ color: '#36368D', textTransform: 'none' }}
              onClick={() => navigate('/users')}
            >
              Users
            </Button>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            style={{ color: '#36368D' }}
            onClick={handleMenuClick}
          >
            <PersonIcon />
          </IconButton>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '200px',
              maxWidth: '200px',
              borderRadius: 20,
              fontFamily: "'Poppins', sans-serif",
            },
          }}
        >
          <Box
            style={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: "'Poppins', sans-serif", // Custom font
                fontWeight: '600', // Stylish bold text
                letterSpacing: '0.5px', // Spacing between letters
              }}
            >
              {userInfo?.username}
            </Typography>
            <hr style={{ margin: '8px 0' }} />
            {roles.includes('webadmin') && (
              <MenuItem
                onClick={() => navigate('/profile')}
                style={{
                  fontFamily: "'Poppins', sans-serif", // Custom font for MenuItem
                  fontWeight: '500',
                  letterSpacing: '0.5px',
                }}
              >
                <PersonIcon style={{ marginRight: '8px' }} /> {/* Add Profile Icon */}
                Profile
              </MenuItem>
            )}
            <MenuItem
              onClick={handleSignOut}
              style={{
                fontFamily: "'Poppins', sans-serif", // Custom font for MenuItem
                fontWeight: '500',
                letterSpacing: '0.5px',
              }}
            >
              <ExitToAppIcon style={{ marginRight: '8px' }} /> {/* Add Sign Out Icon */}
              Sign out
            </MenuItem>
          </Box>
        </Menu>

      </Toolbar>
    </AppBar>
  );
}

export default Navbar;