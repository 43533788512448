import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { awsExports } from './aws-exports';
import Home from './components/Home';
import Assets from './components/Assets';
import Profile from './components/Profile';
import ApiProvider from './context/ApiContext';
import { fetchAuthSession } from 'aws-amplify/auth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Users from './components/Users';
import logo from './logo.png';
import { UserProvider, useUser } from './context/UserContext';
import './customStyles.css'; // Import your custom styles
import Navbar from './components/Navbar';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: awsExports.USER_POOL_CLIENT_ID,
      userPoolId: awsExports.USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: 'hyp.auth.us-east-1.amazoncognito.com',
          scopes: ['openid email phone profile aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/', 'https://example.com/'],
          redirectSignOut: ['http://localhost:3000/', 'https://example.com/'],
          responseType: 'code',
        },
        username: 'true',
        email: 'false',
        phone: 'false',
      }
    }
  }
});

function App() {
  const [jwtToken, setJwtToken] = useState('');
  
  return (
    <Authenticator
      initialState='signIn'
      hideSignUp
      components={{
        SignIn: {
          Header() {
            return (
              <div style={{ textAlign: 'center', padding: '20px', display: 'flex', justifyContent: 'center' }}>
                <img src={logo} className="Nav-logo" alt="logo" style={{ maxWidth: '200px', margin: 'auto' }} />
              </div>
            );
          },
          FormFields() {
            return (
              <>
                <Authenticator.SignIn.FormFields />
              </>
            );
          },
        },
        SignUp: {
          FormFields() {
            window.location.href = '/'; // Redirect to the homepage or sign-in page
            return null; // Prevent rendering
          },
        },
      }}
    >
      {({ signOut, user }) => (
        <ApiProvider>
          <UserProvider>
            <Router>
              <Navbar signOut={signOut} />
              <UserSession user={user} setJwtToken={setJwtToken} />
              <div style={{ margin: '8px 40px' }}>
                <Routes>
                  <Route path="/" element={<Home user={user} token={jwtToken} />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/assets" element={<Assets />} />
                  <Route path="/profile" element={<Profile />} />
                </Routes>
              </div>
            </Router>
          </UserProvider>
        </ApiProvider>
      )}
    </Authenticator>
  );
}

function UserSession({ user, setJwtToken }) {
  const { updateUserInfo } = useUser();

  useEffect(() => {
    const checkUserSession = async () => {
      const session = await fetchAuthSession();
      const { idToken } = session.tokens ?? {};
      const token = idToken ? idToken.toString() : '';
      setJwtToken(token);
      if (token) {
        const roles = fetchGroupsFromToken(token);
        updateUserInfo(user, token); // Pass user info if available
      }
    };

    checkUserSession();
  }, [user, setJwtToken, updateUserInfo]);

  const fetchGroupsFromToken = (token) => {
    if (!token || !token.split('.')[1]) {
      return [];
    }
    const encodedPayload = token.split('.')[1];
    const decodedPayload = atob(encodedPayload);
    const payload = JSON.parse(decodedPayload);
    return payload['cognito:groups'] || [];
  };

  return null; // This component doesn't render anything
}

export default App;