
const Util = {
    formatDate: () => {
        const date = new Date(Date.now());
        const month = date.getMonth() + 1; // Months are 0-indexed
        const day = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        const secondsStr = seconds < 10 ? '0' + seconds : seconds;

        return `${month}/${day}/${year} ${hours}:${minutesStr}:${secondsStr} ${ampm}`;
    },

    generateOID: (col, username) => {
        // Combine the two strings with an underscore
        let combinedString = `${col.replace('DataBucket', '')}_${username}_`;

        // Generate a random string of the specified length
        let randomString = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 8; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        // Append the random string to the combined string
        let finalString = combinedString + randomString;

        return finalString;
    },

    wsMessage: (body) => {
        try {
            const socket = new WebSocket('wss://44.213.183.214:8000/RemoteMessaging');
    
            socket.onopen = (event) => {
                console.log('Connected to WebSocket server');
                socket.send(JSON.stringify(body)); // Send the message when connected
            };
    
            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                if (error.name === 'SecureContextError') {
                    console.error('SSL certificate verification failed');
                }
            };
    
            socket.onclose = (event) => {
                console.log('Disconnected from WebSocket server');
            };
    
            socket.onmessage = (event) => {
                console.log('Received message:', event.data);
            };
        } catch (error) {
            console.error('Error creating WebSocket connection:', error);
        }
    }
}

export default Util;


