import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormLabel,
  Radio
} from '@mui/material';

const MeshAssetForm = ({ control, watch }) => {
  const scaleOptions = [
    { value: 'metric', label: 'Metric' },
    { value: 'imperial', label: 'Imperial' },
    { value: 'custom', label: 'Custom' },
  ];

  const objectOptions = [
    { value: 'shelfObject', label: 'Shelf Object' },
    { value: 'environmentObject', label: 'Environment Object' },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1">3D Transform Offset</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ paddingBottom: 5 }}>Position:</Typography>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Controller
                  name="threedTransformOffset.position.x"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField {...field} label="X" variant="outlined" size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="threedTransformOffset.position.y"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField {...field} label="Y" variant="outlined" size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="threedTransformOffset.position.z"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField {...field} label="Z" variant="outlined" size="small" fullWidth />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ paddingBottom: 5 }}>Rotation:</Typography>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Controller
                  name="threedTransformOffset.rotation.x"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField {...field} label="X" variant="outlined" size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="threedTransformOffset.rotation.y"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField {...field} label="Y" variant="outlined" size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="threedTransformOffset.rotation.z"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField {...field} label="Z" variant="outlined" size="small" fullWidth />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="objectType"
          control={control}
          defaultValue="shelfObject"
          render={({ field }) => (
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Object Type:</FormLabel>
              <RadioGroup
                aria-label="object-type"
                name="objectType"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {objectOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="fileScale"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">File Scale:</FormLabel>
              <RadioGroup
                aria-label="file-scale"
                name="fileScale"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {scaleOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              {field.value === 'custom' && (
                <Controller
                  name="customScaleValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Custom Value"
                      variant="outlined"
                      size="small"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              )}
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="3dProcessing"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle1">3D Processing:</Typography>
              <FormGroup>
                {['Double Sided Mesh', 'Point Cloud', 'Include Directory'].map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        value={option}
                        checked={field.value.includes(option)}
                        onChange={(e) => {
                          const { value, checked } = e.target;
                          if (checked) {
                            field.onChange([...field.value, value]);
                          } else {
                            field.onChange(field.value.filter((v) => v !== value));
                          }
                        }}
                      />
                    }
                    label={option}
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};

export default MeshAssetForm;