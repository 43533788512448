import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const BackButton = ({ pageName }) => {
  const navigate = useNavigate();

  return (
    <IconButton onClick={() => navigate(pageName)} aria-label={`${pageName}`}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
