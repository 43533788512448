import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../context/ApiContext';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal, Button, Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AssetForm from './AssetForm';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { useUser } from '../context/UserContext';
import logo from '../../src/logo.png'
import BackButton from '../components/BackButton'
import Util from '../Util/Util';

const useStyles = makeStyles({
  root: {
    boxShadow: 'none !important',
    '& .MuiPaper-root': {
      boxShadow: 'none !important',
    },
  },
});

function Assets() {
  const { getAllAssetsByScene, getImageAsset, imageArchetypeRMS, createThreeDArchetype, threeDArchetypeRMS, getMeshAsset, getThreeDAsset, createImageArchetype, createAudioArchetype, audioArchetypeRMS, getAudioAsset } = useContext(ApiContext);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [asset, setAsset] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const location = useLocation();
  const { sceneId, sceneName } = location.state;
  const { userInfo } = useUser();
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleMenuClick = (event, assetId,) => {
    setAnchorEl(event.currentTarget)
    setAsset(assetId)
    console.log(assetId)
  };

  const handleArchetype = async () => {
    const assetType = asset.split('Asset')[0];
    console.log(assetType)

    switch (assetType) {
      case "Mesh":
        let meshResp = await getMeshAsset(asset)
        const mesh = meshResp.resource[0];
        let threedasset = await getThreeDAsset(mesh.sceneID, '')
        const meshBody = {
          "objectID": Util.generateOID('ThreeDArchetype', userInfo.username),
          "createTime": Util.formatDate(),
          "modifyTime": "",
          "deleteTime": "",
          "creatorName": userInfo.username,
          "sceneID": mesh.sceneID,
          "isEnacted": mesh.isEnacted,
          "locationID": mesh.locationID,
          "isNetworkVisible": true,
          "scenePose": mesh.realTransform,
          "followTargetID": "",
          "followTargetPoseOffset": {
            "position": mesh.realTransform.position,
            "rotation": mesh.realTransform.rotation
          },
          "isGrabbable": false,
          "isTappable": false,
          "isTouchable": false,
          "canBeHandled": false,
          "isTargetable": false,
          "isScaleable": true,
          "isMappable": true,
          "isAutoGrabbable": false,
          "interactionState": 0,
          "displayName": mesh.assetName,
          "iArchetypableID": threedasset.resource[0].objectID,
          "nameDisplayEnabled": false,
          "CurrRenderType": 1,
          "layerName": "UsableObject"
        }
        await createThreeDArchetype(meshBody)
        threeDArchetypeRMS(meshBody)
        break;
      case "Image":
        let imageResp = await getImageAsset(asset);
        const image = imageResp.resource[0];
        const imageBody = 
        {
          objectID: Util.generateOID('ImageArchetypeDataBucket', image.creatorName),
          createTime: Util.formatDate(),
          modifyTime: "",
          deleteTime: "",
          creatorName: image.creatorName,
          sceneID: image.sceneID,
          isEnacted: image.isEnacted,
          locationID: image.locationID,
          isNetworkVisible: image.isNetworkVisible,
          scenePose: image.imageCameraPose,
          followTargetID: "",
          followTargetPoseOffset: {
              position: image?.imageCameraPose?.position,
              rotation: image?.imageCameraPose?.rotation,
          },
          isGrabbable: true,
          isTappable: true,
          isTouchable: true,
          canBeHandled: true,
          isTargetable: true,
          isScaleable: true,
          isMappable: true,
          isAutoGrabbable: false,
          isThrowable: true,
          interactionState: 0,
          displayName: image.assetName,
          iArchetypableID: "ApexImageAsset_u9_4jkq7d05_ehu",
          nameDisplayEnabled: true
      }   
        await createImageArchetype(imageBody);
        imageArchetypeRMS(imageBody);
        break;
      case "Audio":
        const audioResp = await getAudioAsset(asset);
        const audio = audioResp.resource[0];
        const audioBody = {
          objectID: Util.generateOID('AudioArchetypeDataBucket', audio.creatorName),
          createTime: Util.formatDate(),
          modifyTime: "",
          deleteTime: "",
          creatorName: audio.creatorName,
          sceneID: audio.sceneID,
          isEnacted: audio.isEnacted,
          locationID: audio.locationID,
          isNetworkVisible: audio.isNetworkVisible,
          scenePose: {
              position: {
                x: 0,
                y: 0,
                z: 0
              },
              rotation: {
                x: 0,
                y: 0,
                z: 0,
                w: 1
              },
              localScale: {
                x: 1,
                y: 1,
                z: 1
              }
            },
            followTargetID: "",
            followTargetPoseOffset: {
              position: {
                x: 0,
                y: 0,
                z: 0
              },
              rotation: {
                x: 0,
                y: 0,
                z: 0,
                w: 1
              }
            },
          isGrabbable: true,
          isTappable: true,
          isTouchable: true,
          canBeHandled: true,
          isTargetable: true,
          isScaleable: true,
          isMappable: true,
          isAutoGrabbable: false,
          isThrowable: true,
          interactionState: 0,
          displayName: audio.assetName,
          iArchetypableID: audio.iArchetypableID,
          nameDisplayEnabled: true
      }               
      
        await createAudioArchetype(audioBody);
        audioArchetypeRMS(audioBody);
        break;
      default:
        console.log("Invalid input");
        break;
    }

  }

  const { handleSubmit } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllAssetsByScene(sceneId);
        setData(result);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [getAllAssetsByScene, sceneId]);

  const columns = [
    { name: "assetName", label: "Asset Name", options: { filter: true, sort: false } },
    {
      name: "objectID",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          // Extract the asset type from the objectID
          const assetType = value.split('Asset')[0]; // Split by "Asset" and take the first part
          return assetType; // Return just the type (e.g., "Image", "Mesh", "Video")
        }
      }
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const assetId = tableMeta.rowData[1];
          return (
            <IconButton
              aria-label="more"
              onClick={event => handleMenuClick(event, assetId)}
            >
              <MoreVertIcon />
            </IconButton>
          );
        }
      }
    }
  ];


  return (
    <>
      <Box position="relative" display="flex" justifyContent="center" marginBottom="20px">
        <Box position="absolute" left={0}>
          <BackButton pageName={'/'} />
        </Box>
        <img src={logo} alt="Logo" style={{ width: 384, height: 108 }} />
      </Box>
      <Typography variant='h4' style={{ fontWeight: 500, textAlign: 'center', margin: '20px 0' }}>Scene Management</Typography>
      <Typography variant='h5' style={{ fontWeight: 400, textAlign: 'center', margin: '20px 0' }}>{sceneName}</Typography>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" padding="10px">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          style={{ backgroundColor: 'rgb(54, 54, 141)', marginBottom: '10px' }}
        >
          Register Asset
        </Button>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
            style={{ backgroundColor: 'rgb(54, 54, 141)', marginRight: '8px' }}
          >
            Duplicate
          </Button> */}
          {/* <Button
            variant="contained"
            color="error"
            onClick={handleOpenModal}
            style={{ backgroundColor: '#AF2626' }}
          >
            Delete
          </Button> */}
        </Box>
      </Box>
      <Box style={{ maxHeight: '600px', width: '100%', overflowY: 'auto', padding: '10px' }}>
        <MUIDataTable
          data={data}
          columns={columns}
          style={{ boxShadow: 'none' }}
          options={{
            responsive: "standard",
            filter: true,
            search: true,
            download: false,
            print: false,
            viewColumns: false,
            selectableRows: "multiple",
            customToolbarSelect: () => { },
            setTableProps: () => ({
              className: classes.root,
            }),
          }}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleOpenModal}>Edit</MenuItem>
          <MenuItem onClick={handleArchetype}>Place as Scene Object</MenuItem>
        </Menu>
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div style={{ padding: '20px' }}>
            <AssetForm onSubmit={handleSubmit} onClose={handleCloseModal} sceneId={sceneId} />
          </div>
        </Modal>
      </Box>
    </>
  );
}

export default Assets;